import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet/es/Helmet"

import SEO from "../../components/SEO"
import Heading from "../../components/Heading"
import SkuTile from "../../components/SkuTile"

import styles from "./favorites.module.scss"
import buttonStyles from "../../components/button.module.scss"

import favorites from "../../helpers/favorites"
import { toTitleCase } from "../../helpers"

const Favorites = ({ location }) => {
  const [favoritesRetrieved, setFavoritesRetrieved] = useState(false)
  const [favoritesList, setFavoritesList] = useState([])

  useEffect(() => {
    favorites.getListProducts()
      .then(({ data }) => {
        if (data.list) {
          // format favorites for use with SkuTile
          data.list.forEach(sku => {
            sku.images = {}
            sku.images.plp2 = sku.images.pdp = sku.pdp
            sku.color = toTitleCase(sku.color.name)
            sku.url = sku.url.split("?")[0].split("tamaramellon.com")[1]
            // TODO: Update API call to get tags
            sku.tags = []
            sku.variants.forEach(variant => {
              variant.price = parseInt(sku.price.replace(",",""), 10)
              variant.dateExpected = (variant.backorder) ? variant.backorder.expected_at : ""
              variant.variant_id = variant.shopify_variant_id
              variant.quantity = variant.inventory.available_to_sell
            })
            sku.skus = [sku]
          })
          setFavoritesList(data.list)
        }
        setFavoritesRetrieved(true)
      })
  }, [favoritesRetrieved])

  return(
    <>
      <SEO
        title="Your Favorites"
        description={`Page description here`}
        url={location.pathname}
      />

      <Heading
        tag="h1"
        level="0"
        className={styles.heading}
      >
        Your Favorites
      </Heading>
      <section className={styles.favorites}>
        {favoritesList.length > 0 ?

          <ul className={styles.grid}>
            {favoritesList.map((favorite, i) => (
              <SkuTile
                key={i}
                index={i}
                style={favorite}
                sku={favorite}
                customStyles={styles}
                origin={`favorites`}
              />
            ))}
          </ul>
          // <ul className={styles.grid}>
          //   {favoritesList.map((favorite, i) => (
          //     <Favorite
          //       key={i}
          //       favorite={favorite}
          //     />
          //   ))}
          // </ul>
          :
          <div>
            <p>You don't have any favorites yet.</p>
            <Link
              to="/collections/all"
              className={buttonStyles.btnDark}
            >
              Start Favoriting Now
            </Link>
          </div>
        }
      </section>

      <Helmet>
        <script type="text/javascript">{`
          window.criteo_q = window.criteo_q || [];
          window.criteo_q.push(
            { event: "viewWishlist", ecpplugin: "shopify" }
          );
        `}</script>
      </Helmet>
    </>
  )
}

export default Favorites
